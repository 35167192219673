<template>
<div>
  <Header :getSearch="getSearch"></Header>
  <div class="all_game">
    <div class="title">
      <router-link :to="{name:'AllGame',query:{s:this.$store.state.selectToken}}" >首页&emsp;<span class="Breadcrumb"><i
        class="el-icon-arrow-right "></i></span>&emsp;
      </router-link>
      <router-link
        :to="this.$route.query.game_id == '26'?{name:'SelectGame',query:{game_id:this.$route.query.game_id,game_alias:'yuanshen5',s:this.$store.state.selectToken}}:{name:'SelectGame',query:{game_id:this.$route.query.game_id,game_alias:'games',s:this.$store.state.selectToken}}"
        :id="game_class=='网络游戏'?'gameTypePC':'gameTypePE'" class="gameName"><span>{{ game_name }}</span></router-link>
    </div>
    <div class="list">
      <div class="filter">
        <div class="filter_ground">
          <div v-for="(item,index) in filterData" :key="index" class="equipment">
            <div>{{ item.cat_name }}：</div>
            <ul>
              <el-radio v-for="(items,indexs) in item.entries" :key="indexs" v-model="filterForm[item.cat_id]"
                        @change="filterItemClick(item,items)" :label="items.id">{{ items.name }}
              </el-radio>
            </ul>
          </div>
          <div class="equipment is_gaoji" v-if="filterOptions.length>0">
            <div class="is_gaoji_title">高级选项：</div>
            <ul>
              <!--              <li v-for="(item,index) in filterOptions" :key="index" class="is_gaoji_select" v-show="productTypeSelect === 2 && item.show">-->
              <li v-for="(item,index) in filterOptions" :key="index" class="is_gaoji_select"
                  v-show="item.entries.length !== 0">
                <div class="optionsLable">{{ item.cat_name }}</div>
                <el-select :popper-append-to-body="false"
                          v-model="filterForm[item.cat_id]"
                          multiple
                          collapse-tags
                          clearable
                          filterable
                          @change="optionSelect"
                          placeholder="请选择">
                  <el-option
                    v-for="(items,indexs) in item.entries"
                    :key="indexs"
                    :label="items.name"
                    :value="items.id">
                  </el-option>
                </el-select>
              </li>
            </ul>
          </div>
          <div class="equipment is_gaoji" v-for="(item,index) in filterGrouping" :key="index">
            <div class="is_gaoji_title">{{ item.cat_name }}：</div>
            <ul>
              <li v-for="(Eitem,Eindex) in item.entries" :key="Eindex" class="is_gaoji_select">
                <div class="optionsLable">{{ Eitem.cat_name }}</div>
                <el-select :popper-append-to-body="false"
                          v-model="filterForm[Eitem.cat_id]"
                          multiple
                          collapse-tags
                          clearable
                          filterable
                          @change="optionSelect"
                          placeholder="请选择">
                  <el-option
                    v-for="items in Eitem.entries"
                    :key="items.id"
                    :label="items.name"
                    :value="items.id">
                  </el-option>
                </el-select>
              </li>
            </ul>
          </div>
          <div class="price">
            <div class="price_price">
              <div>价格：</div>
              <input type="text" class="min_price" v-model="scopeValue.min_price" placeholder="请输入">
              <div class="scope">—</div>
              <input type="text" class="max_price" v-model="scopeValue.max_price" placeholder="请输入">
            </div>
            <div class="skin_value" v-if="game_name === '王者荣耀' || game_name === '英雄联盟'">
              <div>皮肤数量：</div>
              <input type="text" class="min_price" v-model="scopeValue.min_skin" placeholder="请输入">
              <div class="scope">—</div>
              <input type="text" class="max_price" v-model="scopeValue.max_skin" placeholder="请输入">
            </div>
            <div class="power_value" v-if="game_name === '天涯明月刀'">
              <div>功力值：</div>
              <input type="text" class="min_price" v-model="scopeValue.min_gongli" placeholder="请输入">
              <div class="scope">—</div>
              <input type="text" class="max_price" v-model="scopeValue.max_gongli" placeholder="请输入">
            </div>
            <div class="charm_value" v-if="game_name === '天涯明月刀'">
              <div>魅力值：</div>
              <input type="text" class="min_price" v-model="scopeValue.min_meili" placeholder="请输入">
              <div class="scope">—</div>
              <input type="text" class="max_price" v-model="scopeValue.max_meili" placeholder="请输入">
            </div>
            <div class="price_filter" @click="scopeValueClick">筛选</div>
          </div>
          <!-- <div class="matchSearch">
            <div>关键词搜索：</div>
            <el-input
              placeholder="请输入关键词搜索"
              class="searchInput"
              v-model="matchCon">
              <img class="searchImg" slot="suffix" src="../../../assets/image/AllGame/search.png" alt=""
                  @click="scopeValueClick">
            </el-input>
          </div> -->
        </div>
      </div>
      <div class="search">
        <div class="search_left">
          <span class="sort" v-for="(item,index) in sortItem" :key="index"
                :class="item.type === sortItemIndex?'sort_checked':''" @click="clickSortItem(item)">
            <span>{{ item.name }}</span>
            <i v-if="item.type !== ''" class="el-icon-caret-top" :class="sortItemMethod === 2?'sort2':''"></i>
            <i v-if="item.type !== ''" class="el-icon-caret-bottom" :class="sortItemMethod === 1?'sort1':''"></i>
          </span>
        </div>
        <div class="search_right" @click="emptyOptions">
          <div class="search_right_text">
            清空所有筛选
          </div>
        </div>
      </div>
      <div class="game_list" v-if="productTypeSelect === 1">
        <div class="game_list_details_box" v-for="(item,index) in gameListData" :key="index"
            :id="index === gameListData.length-1?'game_list_details_box_last':''">
          <div class="game_list_details">
            <router-link class="details_img" target="_blank"
                        :to="{name:'GameDetails',query:{game_id:game_id,id:item.id,s:$store.state.selectToken}}">
              <img :src="item.image" alt="" :style="item.id_api_shangjia?'':'height:100%;object-fit:cover'">
            </router-link>
            <div class="details_info">
              <router-link class="details_info_top" target="_blank"
                          :to="{name:'GameDetails',query:{game_id:game_id,id:item.id,s:$store.state.selectToken}}">
                <span class="DIT_gameName">{{ game_name }}</span>
                <span v-html="item.name"></span>
                <!--                {{}}-->
                <div class="game_list_details_content">
                  <div class="game_list_details_content_title">商品详情</div>
                  <div class="game_list_details_content_detail">
                    {{ item.short_name?item.short_name:item.name }}
                  </div>
                </div>
              </router-link>
              <div class="details_info_mid">
                <span v-for="(categoryItem,index) in item.category" :key="index">
                  {{ categoryItem.name }}:
                  {{ categoryItem.value }}
                </span>
              </div>
              <div class="details_info_bot">
                <span class="release_time">发布时间：{{ item.up_time|moment }}</span>
                <span class="hot_pic">
                  <!-- <img src="@/assets/image/Home/topGamesIco.png" alt=""> -->
                  热度：{{ item.click }}
                </span>
              </div>
            </div>
            <div class="details_price" v-if="item.price > 0">
              ¥{{ item.price }}
              <div></div>
              <img v-if="item.is_sign === 1" src="@/assets/image/AllGame/isSign.png" alt="">
              <img v-if="item.bind_psn === 1" src="@/assets/image/AllGame/bindPsn.png" alt="">
              <img v-if="item.bind_tap === 1" src="@/assets/image/AllGame/bindTap.png" alt="">
            </div>
            <div class="details_price" v-else></div>
            <div class="details_btn">
              <router-link target="_blank" :to="{name:'GameDetails',query:{game_id:game_id,id:item.id,s:$store.state.selectToken}}">查看详情
              </router-link>
              <!-- <router-link target="_blank" :to="{name:'GameDetails',query:{game_id:game_id,id:item.id,s:$store.state.selectToken}}">咨询客服
              </router-link> -->
            </div>
          </div>
        </div>
        <div class="pages">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="PageSize"
            layout="total,prev, pager, next, jumper"
            :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
  
</template>
<script>
import {
  apiProductCategory,
  apiProductPcate,
  apiProductList,
  apiGoldCategory,
  apiGoldAttr,
  apiGoldList,
  apiEquipCategory,
  apiEquipAttr,
  apiEquipList,
  apiCustomerRandom,
  apiHistoryCusAdd,

} from "@/request/API";
import Header from '@/components/header'
export default {
  name: '',
  components: { Header },
  data() {
    return {
      breadCrumbs: '',
      game_id: this.$route.query.game_id,
      game_name: this.$route.params.game_name,
      game_class: this.$route.params.game_class,
      game_image: this.$route.params.game_image,
      game_customer: '',
      matchCon: this.$route.query.matchCon,
      //商品类型
      productType: this.$route.params.game_service,
      //商品类型选中
      productTypeSelect: 1,
      //筛选 表单
      filterForm: {},
      //筛选 单选数据
      filterData: [],
      //筛选 多选下拉框数据
      filterOptions: [],
      filterGrouping: [],
      optionsValue: '',
      //筛选 范围表单
      scopeValue: {
        min_price: '',
        max_price: '',
        // min_gongli:'',
        // max_gongli:'',
        // min_meili:'',
        // max_meili:'',
      },
      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 0,
      // 默认每页显示的条数（可修改）
      PageSize: 15,
      text: ``,
      //排序选项
      sortItem: [
        {name: '综合排序', type: ''},
        {name: '时间', type: 'isnew'},
        {name: '价格', type: 'pricesort'},
        {name: '热度', type: 'redusort'},
        // {name:'功力',type:'gongjisort'},
        // {name:'魅力',type:'meilisort'},
      ],
      //排序当前选项
      sortItemIndex: '',
      //排序方式 0不筛选(默认) 1降序 2升序
      sortItemMethod: 2,
      // rec顶级账号列表
      rec: '',
      //账号列表 数据
      gameListData: [],

      centerDialogVisible: false,

      //装备 昨日成交订单
      yesterdayDeal: 0,
      productId:''
    }
  },
  methods: {
    /**
     * 选择商品类型
     * @param item 商品类型选中项
     */
    productTypeClick(item) {
      this.currentPage = 1
      this.productTypeSelect = item.id
      localStorage.setItem('productTypeSelect', item.id)
      this.filterForm = {}
      this.getProductCategory(this.game_id)
      this.getList();
    },
    /**
     * 分页
     */
    handleCurrentChange(val) {
      this.currentPage = val
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.getList();
    },
    /**
     * 获取 游戏账号 筛选表单数据
     * @param game_id  游戏id
     */
    getProductCategory(game_id) {
      this.filterData = []
      this.filterOptions = []
      apiProductCategory({
        game_id: game_id
      }).then(res => {
        res.data.forEach(item => {
          if (item.is_gaoji === 1) {
            if (item.haveAttr) {
              this.filterOptions.push(item)
              this.$set(this.filterForm, item.cat_id, 0)
            } else {
              this.filterGrouping.push(item)
              item.entries.forEach(entriesItem => {
                this.$set(this.filterForm, entriesItem.cat_id, 0)
              })
            }
          } else {
            this.filterData.push(item)
            this.$set(this.filterForm, item.cat_id, 0)
          }
        })
      })
    },

    /**
     * 游戏筛选 单选
     * @param item   选中列
     * @param items  选中列的项
     */
    filterItemClick(item, items) {
      if (items.haveChild) {
        switch (this.productTypeSelect) {
          case 1:
            apiProductPcate({
              game_id: this.game_id,
              pid: items.id
            }).then(res => {
              for (let resKey in res.data.list) {
                this.filterData.forEach((Ditems, Dindexs) => {
                  if (Ditems.cat_id === parseInt(resKey)) {
                    Ditems.entries.splice(1, Ditems.entries.length)
                    res.data.list[resKey].forEach((keyItem, keyIndex) => {
                      Ditems.entries.push(keyItem)
                    })
                  }
                })
              }
              for (let key in this.filterForm) {
                for (const keyKey in res.data.list) {
                  if (key == keyKey) {
                    this.filterForm[key] = 0
                  }
                }
              }
            })
            break;
        }
      }
      else {
        this.filterData.forEach((Ditems, Dindexs) => {
          if (Ditems.parent_id === item.cat_id) {
            Ditems.entries.splice(1, Ditems.entries.length)
            this.filterForm[Ditems.cat_id] = 0
          }
        })
      }

      if (item.unique_id === 'ajg') {
        this.scopeValue.max_price = ''
        this.scopeValue.min_price = ''
      }
      this.currentPage = 1
      this.getList();
    },
    /**
     * 清空所有选项
     */
    emptyOptions() {
      for (let key in this.filterForm) {
        this.filterForm[key] = 0;
      }
      this.filterData.forEach((item, index) => {
        if (item.parent_id === 1) {
          this.filterData[index].entries.splice(0, this.filterData[1].entries.length)
          let entries = new Object()
          entries.id = 0
          entries.name = '全部'
          this.filterData[index].entries.push(entries)
        }
      })
      for (let scopeValueKey in this.scopeValue) {
        this.scopeValue[scopeValueKey] = ''
      }
      this.currentPage = 1
      this.getList();
    },
    /**
     * 排序 点击事件
     * @param item 所点击项
     */
    clickSortItem(item) {
      if (this.sortItemIndex === item.type) {
        if (this.sortItemMethod === 1) {
          this.sortItemMethod = 2
        } else {
          this.sortItemMethod = 1
        }
      } else {
        this.sortItemIndex = item.type
        this.sortItemMethod = 2
      }
      this.currentPage = 1
      this.getList();
    },
    /**
     * 筛选 点击
     */
    scopeValueClick() {
      this.currentPage = 1
      this.getList();
    },
    /**
     * 账号列表 数据点击
     * @param item 所点击项
     */
    clickGameListItem(item) {
      let routeUrl = this.$router.resolve({
        name: 'GameDetails',
        query: {
          game_id: this.game_id,
          id: item.id,
          s:this.$store.state.selectToken
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    //高级选项 下拉框监听事件
    optionSelect() {
      this.currentPage = 1
      this.getList();
    },
    /**
     * 获取 游戏账号列表
     */
    getProductList() {
      apiProductList({
        game_id: this.game_id,
        rec: this.rec,
        category: this.filterForm,
        scope: this.scopeValue,
        matchCon: this.matchCon,
        sort: {
          type: this.sortItemIndex,
          method: this.sortItemMethod,
        },
        page: this.currentPage
      }).then(res => {
        this.game_name = res.data.game.game_name
        this.game_class = res.data.game.game_class
        this.game_customer = res.data.game.game_customer
        this.productType = res.data.game.game_service
        this.game_image = res.data.game.game_image

        this.gameListData = res.data.list
        this.totalCount = res.data.total
        this.PageSize = res.data.pageSize
        this.currentPage = res.data.page
        document.title = `${this.game_name} — 选号网站`
      })
    },

    /**
     * 获取 列表数据
     */
    getList() {
      this.getProductList();
    },
    getSearch(matchCon) {
      this.matchCon = matchCon
      this.getProductList();
    },
    onLoad() {
      this.getList()
      this.getProductCategory(this.game_id)
    }
  },
  mounted() {

  },
  created() {
    document.title = ''
    if (localStorage.getItem('productTypeSelect')) {
      this.productTypeSelect = localStorage.getItem('productTypeSelect') * 1
    }
    if (this.$route.params.productTypeSelect) {
      this.productTypeSelect = this.$route.params.productTypeSelect
    }
    this.getProductCategory(this.$route.query.game_id)
    // this.getProductCategory(this.$route.query.game_id)
    if (this.$route.params.type) {
      this.sortItemIndex = 'isnew',
        //排序方式 0不筛选(默认) 1降序 2升序
        this.sortItemMethod = 1
    }
    if (this.$route.query.game_id == 8) {
      this.sortItem = [
        {name: '综合排序', type: ''},
        {name: '时间', type: 'isnew'},
        {name: '价格', type: 'pricesort'},
        {name: '热度', type: 'redusort'},
        {name: '功力', type: 'gonglisort'},
        {name: '魅力', type: 'meilisort'},
      ]
      this.scopeValue = {
        min_price: '',
        max_price: '',
        min_gongli: '',
        max_gongli: '',
        min_meili: '',
        max_meili: '',
      }
    } else if (this.$route.query.game_id == 13 || this.$route.query.game_id == 12) {
      this.sortItem = [
        {name: '综合排序', type: ''},
        {name: '时间', type: 'isnew'},
        {name: '价格', type: 'pricesort'},
        {name: '热度', type: 'redusort'},
        {name: '皮肤数量', type: 'skinsort'},
      ]
      this.scopeValue = {
        min_price: '',
        max_price: '',
        min_skin: '',
        max_skin: '',
      }
    } else {
      this.sortItem = [
        {name: '综合排序', type: ''},
        {name: '时间', type: 'isnew'},
        {name: '价格', type: 'pricesort'},
        {name: '热度', type: 'redusort'},
      ]
      this.scopeValue = {
        min_price: '',
        max_price: '',
      }
    }
    if (this.$route.params.rec) {
      this.rec = this.$route.params.rec
    }
    if (this.$route.params.breadCrumbs) {
      this.breadCrumbs = this.$route.params.breadCrumbs
    }
    //初始化数据
    if (this.$route.params.list) {
      this.game_id = this.$route.query.game_id
      this.game_name = this.$route.params.game_name
      this.game_class = this.$route.params.game_class
      this.matchCon = this.$route.query.matchCon
    }
    //初始化数据
    this.getList()

  },
  computed: {},
  beforeRouteUpdate(to, from, next) {
    this.game_id = to.query.game_id
    this.game_class = to.params.game_class
    this.game_name = to.params.game_name
    this.matchCon = to.query.matchCon
    if (this.game_id == 8) {
      this.sortItem = [
        {name: '综合排序', type: ''},
        {name: '时间', type: 'isnew'},
        {name: '价格', type: 'pricesort'},
        {name: '热度', type: 'redusort'},
        {name: '功力', type: 'gonglisort'},
        {name: '魅力', type: 'meilisort'},
      ]
      this.scopeValue = {
        min_price: '',
        max_price: '',
        min_gongli: '',
        max_gongli: '',
        min_meili: '',
        max_meili: '',
      }
    } else if (this.$route.query.game_id == 13 || this.$route.query.game_id == 12) {
      this.sortItem = [
        {name: '综合排序', type: ''},
        {name: '时间', type: 'isnew'},
        {name: '价格', type: 'pricesort'},
        {name: '热度', type: 'redusort'},
        {name: '皮肤数量', type: 'skinsort'},
      ]
      this.scopeValue = {
        min_price: '',
        max_price: '',
        min_skin: '',
        max_skin: '',
      }
    } else {
      this.sortItem = [
        {name: '综合排序', type: ''},
        {name: '时间', type: 'isnew'},
        {name: '价格', type: 'pricesort'},
        {name: '热度', type: 'redusort'},
      ]
      this.scopeValue = {
        min_price: '',
        max_price: '',
      }
    }
    this.currentPage = 1
    this.getList()
    this.getProductCategory(to.query.game_id)
    next()
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem('productTypeSelect', 1)
    if (to.name !== 'SelectGame') {
      document.title = '选号网站'
      next()
    }
    next()
  },

}
</script>
<style lang="less">
.contactCusSer {
  width: 500px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  overflow: visible;

  .el-dialog__body {
    padding: 10px 15px 20px;
    position: relative;

    #contactCusSer {
      width: 470px;

      .contactCusSer_title {
        background: #313131;
        color: #FFFFFF;
        text-align: center;
        border-radius: 10px;
        height: 33px;
        line-height: 33px;
        font-size: 20px;
      }

      .contactCusSer_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;

        .CL_item {
          width: 450px;
          height: 26px;
          margin: 0 0 10px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          div {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #000000;
          }

          .CL_btn {
            width: 120px;
            display: flex;
            flex-direction: row;

            div, a {
              margin: 0 10px 0 0;
              font-size: 16px;
              background: #f7d969;
              width: 54px;
              height: 26px;
              line-height: 26px;
              text-align: center;
              border-radius: 7px;
              cursor: pointer;
            }

            a {
              margin: 0;
              background: #f19958;
            }

          }

          #input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: -10;
          }
        }
      }

      .onlineCus {
        margin: 20px auto;
        width: 100px;
        text-align: center;
        background-color: rgb(255, 219, 81);
        color: black;
        padding: 10px 18px;
        font-weight: bold;
        font-size: 16px;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;
        display: block;
      }

      .contactCusSer_tip {
        text-align: center;
        color: black;
        margin: 4px 0 0 0;
        //font-weight: 600;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 14px;

        span {
          display: inline-block;
          color: #FF5454;
        }
      }

    }
  }

  .el-dialog__header {
    padding: 0;
  }
}
</style>
<style lang='less' scoped>
/deep/ .el-radio__input {
  display: none !important;
}

/deep/ .el-select__caret {
  font-size: 18px !important;
}

/deep/ .el-input--suffix .el-input__inner {
  height: 30px !important;
  width: 240px !important;
  line-height: 30px !important;
  font-size: 18px !important;
}

/deep/ .el-input__icon {
  line-height: 0;
}

/deep/ .el-input__inner::-webkit-input-placeholder {
  font-size: 14px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #BFBFBF;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #6A86E5;
}

/deep/ .el-select .el-input__inner:focus {
  border-color: #3D59BE;
}

/deep/ .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background: #f5f7fa;
}

/deep/ .el-select-dropdown__item.selected {
  color: #6A86E5 !important;
  font-weight: normal !important;
}

/deep/ .el-pagination {
  font-weight: 400;
  font-family: PingFangSC-Light, PingFang SC;
}

/deep/ .el-select-dropdown el-popper {
  min-width: 224px;
  left: 146px;
}

/deep/ .el-select-dropdown__item /deep/ .selected {
  color: #6A86E5 !important;
}

/deep/ *.popper__arrow {
  left: 345px !important;
}

.all_game {
  min-height: 100vh;
  overflow-x: hidden;
  width: 1200px;
  margin: 0 auto 30px;
  .title {
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 5px;

    a {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;

      .Breadcrumb {
        font-size: 12px;
      }
    }

    .gameName {
      span {
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #4B61B0;
        // margin-left: 23px;
      }
    }

    // #gameTypePC {
    //   background: url("../../../assets/image/AllGame/gameTypePC.png") no-repeat left;
    // }

    // #gameTypePE {
    //   background: url("../../../assets/image/AllGame/gameTypePE.png") no-repeat left;
    // }
  }

  .list {
    .product_type {
      padding: 20px 40px 0;
      background: #FFFFFF;

      #product_type {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #E2E2E2;
        padding: 0 0 20px 0;

        .product_type_img {
          width: 90px;
          height: 90px;
          margin: 0 30px 0 0;

          img {
            border-radius: 20px;
            width: 90px;
            height: 90px;
            object-fit: cover;
          }
        }

        .product_type_content {
          .product_type_title {
            font-size: 24px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 33px;
            margin: 0 0 9px 0;
          }

          .product_type_list {
            display: flex;
            flex-direction: row;
            //padding: 0 10px;
            .product_type_option {
              font-size: 18px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #5B5B5B;
              line-height: 25px;
              cursor: pointer;
              margin: 0 38px 0 0;
              padding: 3px 10px 2px;
            }

            .product_type_select {
              padding: 3px 10px 2px;
              background: rgba(252, 173, 19, 0.22);
              border-radius: 4px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 500;
              color: #3D59BE;
              //opacity: 0.5;
            }

            .product_type_option:last-of-type {
              margin: 0;
            }
          }
        }
      }
    }

    .filter {
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .yesterdayDeal {
        margin: 20px 40px 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        .YD_num {
          margin: 0 30px 0 0;
          width: 100px;
          height: 22px;
          padding: 0 11px;
          background: url("../../../assets/image/AllGame/yesterdayDeal.png") no-repeat;
          background-size: 100% 100%;
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          text-align: center;
        }

        .YD_checkInfo {
          font-size: 12px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
          line-height: 17px;
          cursor: pointer;
        }

        .YD_checkInfo:hover {
          color: #3D59BE;
        }
      }

      .filter_ground {
        margin: 30px 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div {
          //margin-bottom: 20px;
        }

        .gaming_zone, .server, .profession, .equipment {
          border-bottom: 1px solid #F1F1F1;
          margin: 0 0 15px 0;

          div {
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #8F8F8F;
            float: left;
          }

          ul {
            width: 1020px;
            float: right;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            /deep/ .el-radio {
              margin: 0 20px 15px 10px;
              padding: 5px 10px;
            }

            /deep/ .el-radio.is-checked {
              margin: 0 10px 15px 10px;
              padding: 2px 10px;
              background: rgba(101,153,255,0.44);
              border-radius: 4px;

              .el-radio__label {
                //font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #5C74C8;
                line-height: 20px;
              }
            }

            /deep/ .el-radio__label {
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #5B5B5B;
              cursor: pointer;
              padding: 0;
            }

            span {

            }
          }

          .gaming_zone_select {
            text-align: center;
            display: inline-block;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #3D59BE;
            background-color: #fbedcf;
            border-radius: 8px;
            padding: 0 20px;
            margin: 8px;
            line-height: 35px;
          }
        }

        .is_gaoji {
          .is_gaoji_title {
            margin: 5px 0;
          }

          ul {
            .is_gaoji_select {
              display: flex;
              align-items: center;
              width: 33.33%;
              margin: 0 0 15px 0;

              .optionsLable {
                max-width: 90px;
                min-width: 50px;
                margin-right: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #5B5B5B;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }

        .advanced_options {
          margin: 0;

          .advanced_options_title {
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #8F8F8F;
            float: left;
            margin: 20px 0;
          }

          .select_box {
            width: 1010px;
            float: right;
            padding: 0 10px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin: 0;

            .select_box_title {
              font-size: 16px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #5B5B5B;
              margin-right: 10px;
            }

            div {
              margin-right: 25px;
            }
          }
        }

        .price {
          height: 40px;
          line-height: 40px;
          display: flex;
          flex-direction: row;
          //justify-content: space-between;
          .price_price, .power_value, .charm_value, .skin_value {
            display: flex;
            align-items: center;
            height: 40px;
            margin: 0 0 0 40px;

            div:first-of-type {
              margin: 0;
              margin-right: 10px;
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #8F8F8F;
            }

            .min_price, .max_price {
              display: flex;
              width: 80px;
              height: 30px;
              background: #F4F4F4;
              border-radius: 15px;
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #8F8F8F;
              line-height: 30px;
              text-align: center;
              border: none;
              padding: 0 10px;

            }

            .min_price::-webkit-input-placeholder, .max_price::-webkit-input-placeholder {
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #8F8F8F;
              line-height: 22px;
            }

            .scope {
              margin: 0 10px;
              color: #979797;
            }
          }

          .price_price {
            margin: 0;
          }

          .price_filter {
            width: 90px;
            height: 34px;
            line-height: 34px;
            background: linear-gradient(133deg,#5b71bf 45%, #5576ed 100%);
            border-radius: 19px;
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            margin: 0 0 0 27px;
            cursor: pointer;
          }
        }

        .matchSearch {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 20px 0 0;

          div {
            width: 120px;
            white-space: nowrap;
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #8F8F8F;
          }

          .searchInput {
            width: 288px;

            /deep/ .el-input__inner {
              padding-right: 50px !important;
              width: 288px !important;
              height: 40px !important;
              border: 1px solid #283748;
              border-radius: 28px;
              font-size: 16px !important;
              font-family: PingFang SC, PingFang SC-Light;
              font-weight: 300;
              text-align: LEFT;
              color: #5b5b5b;

            }

            /deep/ .el-input__suffix {
              right: 20px;
              top: 5px;
            }

            .searchImg {
              width: 27px;
              height: 27px;
              cursor: pointer;
            }
          }
        }

      }
    }

    .search {
      margin: 10px 0;
      height: 60px;
      border-radius: 30px;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 50px;

      .search_left {
        width: 550px;
        display: flex;
        align-items: center;

        .sort {
          margin-right: 50px;
          position: relative;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #8F8F8F;
          cursor: pointer;

          i {
            position: absolute;
            left: 100%;
          }

          .el-icon-caret-top {
            top: 0px;
          }

          .el-icon-caret-bottom {
            top: 7px;

          }
        }

        .sort_checked {
          span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #5C74C8;
          }

          .sort1, .sort2 {
            color: #5C74C8;
          }
        }
      }

      .search_mid {
        position: relative;
        margin-right: 40px;

        input {
          width: 220px;
          height: 56px;
          background: #FFFFFF;
          border-radius: 28px;
          border: 2px solid #283748;
          padding: 0 55px 0 25px;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
        }

        .search_btn {
          width: 41px;
          height: 41px;
          position: absolute;
          top: 10px;
          left: 250px;

          img {
            height: 100%;
            width: 100%;
            cursor: pointer;
            object-fit: cover;
          }
        }
      }

      .search_right {
        background: url("../../../assets/image/AllGame/delFilter.png") no-repeat left;

        .search_right_text {
          font-family: PingFangSC-Light, PingFang SC;
          margin: 5px 0 5px 25px;
          font-size: 16px;
          font-weight: 400;
          color: #BFBFBF;
        }
      }

      .search_right:hover {
        cursor: pointer;
        background: url("../../../assets/image/AllGame/delFilterHover.png") no-repeat left;

        .search_right_text {
          color: #5C74C8;
        }
      }
    }

    .game_list {
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .game_list_details_box {
        width: 1200px;
        border-bottom: 1px solid #E2E2E2;
        display: flex;
        flex-direction: column;
        position: relative;

        .game_list_details {
          height: 150px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 30px 40px;
          //cursor: pointer;
          .details_img {
            width: 260px;
            height: 145px;
            cursor: pointer;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .details_info {
            width: 450px;
            margin-right: 48px;

            .details_info_top {
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              margin-bottom: 10px;
              height: 78px;
              display: -webkit-box;
              display: -ms-grid;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
              line-height: 20px;
              overflow: hidden;
              text-align: justify; /*实现两端对齐*/
              text-justify: newspaper; /*通过增加或减少字或字母之间的空格对齐文本*/
              word-break: break-all; /*允许在单词内换行*/

              .DIT_gameName {
                background: #CFDDFF;
                border-radius: 15px;
                font-size: 15px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 400;
                color: #3D59BE;
                padding: 0 17px 0 18px;
                margin-right: 5px;
              }

              .game_list_details_content {
                padding: 20px;
                background: rgba(0, 0, 0, 0.8);
                display: none;
                position: absolute;
                top: 110px;
                left: 213px;
                width: 685px;

                .game_list_details_content_title {
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 500;
                  color: #CFDDFF;
                  line-height: 22px;
                  margin-bottom: 7px;
                }

                .game_list_details_content_detail {
                  //font-size: 14px;
                  //font-family: PingFangSC-Thin, PingFang SC;
                  //font-weight: 300;
                  //color: #5B5B5B;
                  //line-height: 20px;
                  //width: 1122px;

                  font-size: 14px;
                  font-family: PingFangSC-Thin, PingFang SC;
                  font-weight: 300;
                  color: #FFFFFF;
                  line-height: 20px;
                }
              }
            }

            .details_info_mid {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin-bottom: 10px;

              span {
                width: 33.33%;
                font-size: 14px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 400;
                color: #8F8F8F;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .details_info_bot {
              display: flex;
              flex-direction: row;

              span {
                font-size: 14px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 400;
                color: #8F8F8F;
              }

              .release_time {
                margin-right: 100px;
              }

              .hot_pic {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                  width: 18px;
                  height: 18px;
                  object-fit: cover;
                  margin: 0 6px 0 0;
                }
              }
            }
          }

          .details_price {
            text-align: center;
            width: 140px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #FC4A00;
          }

          .details_btn {
            a {
              width: 150px;
              height: 40px;
              font-size: 16px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #000000;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border: 1px solid #283748;
              border-radius: 28px;
            }

            a:first-of-type {
              border: 1px solid #768DDD;
              background: #768DDD;
              margin-bottom: 11px;
              color: #fff;
            }
            

          }
        }

      }

      .game_list_details_box:hover {
        background: #F1F4FF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        position: relative;

        .game_list_details {
          .details_info {
            .details_info_top:hover {
              .game_list_details_content {
                //background: #FFFFFF;
                display: block;
                z-index: 9998;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
              }

              .game_list_details_content:hover {
                display: none;
              }
            }
          }
        }

      }

      #game_list_details_box_last {
        border-bottom: none;
      }

      .pages {
        margin: 50px 0 30px 0;
      }
    }

  }
}

</style>
