<template>
  <div class="game-header">
    <div class="header-weapper">
      <div class="header-left">
        <img :src="$store.state.channelConfig.top_logo">
<!--        <img src="@/assets/image/Header/headerLogo.png">-->
      </div>
      <div class="header-right" v-if="headerSearch">
        <input type="text" v-model="search_input_text" @keyup.enter="searchBtnClick" placeholder="请输入关键词进行搜索">
        <div class="search_btn" @click="searchBtnClick">
          <img src="@/assets/image/Footer/search.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameHeader',
  props: {
    //搜索点击函数
    getSearch: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      headerSearch: true,
      search_input_text: ''
    }
  },
  created() {
    if(location.href.indexOf('selectgame') != -1){
      this.headerSearch = true
    } else {
      this.headerSearch = false
    }
  },
  methods: {
    searchBtnClick() {
      this.getSearch(this.search_input_text)
    }
  }
}
</script>

<style lang="less" scoped>
.game-header{
  width: 100vw;
  height: 150px;
  background:#4459a4;
  display: flex;
  align-items: center;
  .header-weapper{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left{
      width: 316px;
      height: 90px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .header-right{
      position: relative;
      input {
        // width: 18.23vw;
        width: 260px;
        height: 46px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 2px solid #283748;
        padding: 0 62px 0 28px;
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #5B5B5B;
      }

      input::-webkit-input-placeholder {
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #E2E2E2;
      }

      .search_btn {
        width: 41px;
        height: 41px;
        position: absolute;
        top: 13px;
        // left: 305px;
        right: 11px;
        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }
  }

}
</style>
